.popover.popover-details {
  @extend .shadow;
  max-width: 960px;

  .row {
    @extend .gx-5, .pt-2;

    strong {
      @extend .text-nowrap;
    }
  }
}
