@import "./scss/bootstrap";
@import "react-toastify/dist/ReactToastify.min.css";

// Force scrollbar to display
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1em;
  height: 0.6em;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: darkgrey;
}

.btn-round {
  border-radius: 50px;
}

/* Remove arrows on number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

html,
body,
#root {
  height: 100%;
}

.Toastify__toast-container {
  top: 55px !important;
}

.nav-item .btn-primary:hover  {
  background-color: #7482b2;
}

.nav-item .btn-primary:focus-visible {
  background-color: #7482b2;
}
