.label-required {
  @extend .text-body-tertiary, .ms-2, .h6;
  vertical-align: middle;
}

.roles-dropdown {
  .dropdown-toggle {
    &::after {
      @extend .d-none;
    }

    // &.show {
    //   @extend .border-dark-subtle;
    //   border-bottom-left-radius: 0px;
    //   border-bottom-right-radius: 0px;
    // }
  }

  // .dropdown-menu {
  //   top: -10px !important;
  //   border-top-left-radius: 0px;
  //   border-top-right-radius: 0px;
  // }
}
