@import "../../App.scss";

.logit-table {
  @extend .table;
  cursor: pointer;
  td {
    max-width: 140px;
  }
  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
  }
  tr:focus {
    outline: none;
    border: 3px solid #c8cfe3;
    box-shadow: 5rem #c8cfe3;
  }
}

.reporter-accounts-expanded-row {
  @extend .bg-body-tertiary;

  .reporter-accounts-expanded-row-table {
    * {
      @extend .bg-transparent;
    }
  }
}
