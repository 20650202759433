*,
.reporter-accounts-expanded-row .reporter-accounts-expanded-row-table {
  .bg-primary {
    background-color: unset !important;
  }
  .bg-red {
    background-color: #f7dbd9 !important;
    color: #852a24 !important;
  }

  .bg-blue {
    background-color: #d3eaf5 !important;
    color: #0e4764 !important;
  }

  .bg-yellow {
    background-color: #f2dda9 !important;
    color: #775403 !important;
  }

  .bg-purple {
    background-color: #d9c9f8 !important;
    color: #2f1660 !important;
  }
}
