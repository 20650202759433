#side-navigation-menu {
  width: 16rem;
  //transition: width 0.15s ease-in-out;
  text-wrap: nowrap;

  .nav-external-icon {
    @extend .ms-2;
  }

  .nav-header {
    @extend .mb-2, .d-flex, .w-100, .align-items-center, .fw-semibold;

    svg {
      @extend .text-primary;
      margin: 0 0.8rem 0 0;
      height: 100%;
      width: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
    }
  }

  .nav-menu-list {
    @extend .pb-4;

    ul {
      @extend .list-unstyled, .ms-4;

      li {
        @extend .d-flex, .align-items-center;

        a,
        .side-nav-link {
          @extend .text-decoration-none,
            .w-100,
            .mb-1,
            .p-1,
            .px-2,
            .rounded,
            .text-muted;

          &.nav-active {
            @extend .bg-primary, .bg-opacity-10, .text-dark, .fw-semibold;
          }

          &:hover {
            @extend .bg-primary, .bg-opacity-10, .text-dark, .cursor-pointer;
          }
        }
      }
    }
  }

  &.collapse-side-nav {
    @extend .d-flex, .flex-column, .align-items-center, .px-0;
    width: 4rem;

    #side-navigation-list {
      @extend .w-100, .d-flex, .flex-column;

      .nav-menu-title,
      ul {
        @extend .d-none;
      }
    }

    .nav-header {
      @extend .d-block, .text-center;
    }

    .nav-menu-list {
      @extend .w-100;
    }

    svg {
      @extend .m-0;
    }
  }
}

#top-navigation {
  #nav-logo {
    width: 2.5rem;
    height: 2.5rem;
  }

  #nav-profile-circle {
    @extend .border-0, .bg-light, .fw-bold, .p-0;

    border-radius: 16rem;
    width: 2.5rem;
    height: 2.5rem;
  }
}

.side-navigation-popover {
  @extend .shadow, .ms-2;
  min-width: 14rem;
  ul {
    @extend .list-unstyled;

    li {
      @extend .d-flex, .align-items-center;

      a,
      .side-nav-link {
        @extend .text-decoration-none,
          .w-100,
          .mb-1,
          .p-1,
          .px-2,
          .rounded,
          .text-muted;

        &.nav-active {
          @extend .bg-primary, .bg-opacity-10, .text-dark, .fw-semibold;
        }

        &:hover {
          @extend .bg-primary, .bg-opacity-10, .text-dark, .cursor-pointer;
        }
      }
    }
  }
}

// Has to be outside because the popover is not directly in the navigation

#nav-profile-popover {
  @extend .p-3, .shadow;

  .nav-profile-list-item {
    @extend .d-flex, .align-items-center, .mb-2;

    svg {
      @extend .text-muted, .me-2;

      width: 1.25rem;
      max-height: 1.25rem;
      height: 100%;
    }

    small {
      @extend .d-block, .text-muted, .position-relative;

      bottom: -0.25rem;
    }
  }

  .nav-profile-links {
    @extend .list-unstyled, .mb-0;

    li,
    .side-nav-link {
      @extend .text-decoration-none,
        .w-100,
        .mb-1,
        .p-1,
        .px-2,
        .rounded,
        .text-muted;

      &:hover {
        @extend .bg-primary, .bg-opacity-10, .text-dark, .cursor-pointer;
      }
    }
  }
}
