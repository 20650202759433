.status-dot {
  @extend .position-relative, .ps-4;

  &.status-dot::before {
    @extend .position-absolute;
    background-color: $orange;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
  }

  &.status-dot-active::before {
    @extend .bg-success;
  }

  &.status-dot-archived::before {
    @extend .bg-danger;
  }
}

.linked-counter {
  @extend .position-absolute,
    .bg-light,
    .shadow-sm,
    .border,
    .border-dark-subtle,
    .text-dark,
    .d-flex,
    .align-items-center,
    .justify-content-center;

  border-radius: 10rem;
  padding: 0.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  top: -0.25rem;
  right: 0.5rem;
  font-size: 1rem;
  line-height: 1;
  transform: translateX(100%);
}

.table-responsive > table.table {
  min-width: 900px;
}
